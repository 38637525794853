<template>
  <div class="main-content white">
    <div class="edit-msg">
      <textarea
        placeholder="請輸入成團通知訊息"
        v-model="notification"
      ></textarea>
    </div>
  </div>
</template>

<script>
import memberSettingNotificationMixin from '@/mixins/member-setting-notifications';
export default {
  mixins: [memberSettingNotificationMixin],
  data: function() {
    return {
      key: 'default_valid_notification', //成團
      default: '尚未付款者，請於期限內儘速付款'
    };
  }
};
</script>
